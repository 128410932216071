type AnalyticsJS = SegmentAnalytics.AnalyticsJS;
type AnalyticsCallback = (analytics: AnalyticsJS) => void;

let userTraits: Record<string, unknown> = {};

export const withAnalytics = (cb: AnalyticsCallback): void => {
  if (window.analytics != null) {
    cb(window.analytics);
  }
};

export const identify = (
  userId: string,
  traits?: { email: string },
  options?: { context: { groupId: string } }
): void => {
  if (traits) userTraits = traits;

  withAnalytics((analytics) => {
    analytics.identify(userId, traits, options);
    // https://posthog.com/docs/libraries/segment#identifying-users
    if ((window as any).posthog != null) {
      (window as any).posthog.identify(userId, traits);
    }
  });
};

export const track = (event: string, properties?: Object): void => {
  withAnalytics((analytics) => {
    analytics.track(event, properties, {
      context: {
        traits: userTraits,
      },
    });
  });
};

export const page = (url: string): void => {
  withAnalytics((analytics) => {
    if (!excludePageView(url)) {
      analytics.page(url);
    }
  });
};

export const excludePageView = (url: string): boolean => {
  return url.startsWith("/surveys-public");
};
